<template>
  <div class="profile-main">
    <profile-main-info />
    <ui-alert v-model="visibleDeleteAccountAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-want-to-delete-your-account') }}</div>
        <div class="ui-alert__text">{{ $t('alert-for-deleted-account') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="deleteAccount" color="error">{{ $t('delete-account') }}</ui-button>
        <ui-button @click="visibleDeleteAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
<!--    <registration-for-event />-->
    <div class="profile-main__footer">
      <UiSelectLanguages
          :options="getLanguages"
          v-model="language"
          :label="$t('language')"
          class="profile-main__footer-languages"
          @input="changeLanguage"
      />
      <p @click="visibleDeleteAccountAlert = true" class="profile-main__footer-delete-account">{{ $t('delete-profile') }}</p>
    </div>
  </div>
</template>

<script>
import ProfileMainInfo from "@/components/template/profile/profile-main/ProfileMainInfo";
import UiAlert from "@/components/ui/UiAlert";
import UiButton from "@/components/ui/UiButton";
import {mapActions, mapGetters} from "vuex";
import UiSelectLanguages from "@/components/ui/UiSelectLanguages.vue";
import { setCookie } from "@/utils/cookie";
import {getDate} from "../../utils/general";
export default {
  name: "ProfileMain",
  components: {
    UiSelectLanguages,
    ProfileMainInfo,
    UiAlert,
    UiButton
  },

  data() {
    return {
      visibleDeleteAccountAlert: false,
      language: null
    }
  },

  methods: {
    ...mapActions([
      'reqDeleteAccount',
      'languages',
      'update'
    ]),

    deleteAccount() {
      this.reqDeleteAccount()
          .then(() => {
            this.visibleDeleteAccountAlert = false
            setTimeout(() => {
              this.$router.push('/')
            },300)
          })
    },

    changeLanguage () {
      const data = { ...this.getUser, locale: this.language.code }
      data.documentIssueDate = getDate(data?.documentIssueDate)

      this.update(data).then(() => {
        setTimeout(() => {
          this.$i18n.locale = this.language.code
          setCookie('locale', this.language.code)
          location.reload();
        }, 500)
      })
    }
  },

  computed: {
    ...mapGetters(['getLanguages', 'getUser'])
  },

  mounted() {
    setTimeout(() => {
      this.languages().then(() => {
        this.getLanguages.forEach(el => {
          if(el.code === this.getUser?.locale) {
            this.language = el
            this.$i18n.locale = el.code
            setCookie('locale', el.code)
          }
        })
      })
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.profile-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 774px;
  margin-left: 250px;

  @media (max-width: 1300px) {
    margin-left: 50px;
    margin-right: 20px;
  }

  @media (max-width: 992px) {
    margin-left: 0;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @media (min-width: 1200px) {
      display: none;
    }

    &-delete-account {
      cursor: pointer;
      font-size: 12px;
      line-height: 11px;
      text-align: center;
      color: #9A9A9A;
    }

    &-languages {
      margin: 0 auto;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    cursor: pointer;

    img {
      cursor: pointer;
      width: 32px;
      height: 32px;

      @media (min-width: 1200px) {
        display: none;
      }
    }

    @media (max-width: 1200px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
</style>
